import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-chronoblog/src/components/page/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "bring-reactivity-to-your-angular-templates-with-the-letdirective"
    }}>{`Bring reactivity to your Angular templates with the LetDirective`}</h1>
    <h5 {...{
      "id": "october-16-2020--aptitude-softwares-office"
    }}>{`October 16, 2020 | `}<a parentName="h5" {...{
        "href": "http://twitter.com/AptitudeSW"
      }}>{`Aptitude Software's`}</a>{` office`}</h5>
    <hr></hr>
    <ul>
      <li parentName="ul">{`⌚ `}<strong parentName="li">{`Duration`}</strong>{`: ~45 minutes`}</li>
      <li parentName="ul">{`👨‍💻 `}<strong parentName="li">{`Form`}</strong>{`: live coding`}</li>
    </ul>
    <hr></hr>
    <p>{`This is a 100% live coding session when we jumped straight to code editors and implemented our ow Angular structural
directive for binding RxJS Observables to the template and viewing different templates for each of the Observable notifications.`}</p>
    <p>{`This talk is heavily inspired by a two-part article of mine under the same name published on `}<a parentName="p" {...{
        "href": "https://indepth.dev"
      }}>{`indepth.dev`}</a>{`
[`}<a parentName="p" {...{
        "href": "https://indepth.dev/bring-reactivity-to-your-angular-templates-with-the-letdirective-part-1"
      }}>{` 1 `}</a>{`] [`}<a parentName="p" {...{
        "href": "https://indepth.dev/bring-reactivity-to-your-angular-templates-with-the-letdirective-part-2"
      }}>{` 2 `}</a>{`].`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      